import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; 

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [apiValue, setApiValue] = useState('');
    const [apiCreated, setApiCreated] = useState(false);
    const [apiError, setApiError] = useState('');
    const [progress, setProgress] = useState(''); // New state to store progress value


    useEffect(() => {
        // Check localStorage for login state on page load
        const isLoggedIn = localStorage.getItem('loggedIn');
        if (isLoggedIn === 'true') {
            setLoggedIn(true);
        }
        // Check localStorage for build version
        const storedBuildVersion = localStorage.getItem('buildVersion');
        const currentBuildVersion = process.env.REACT_APP_BUILD_VERSION;
        if (storedBuildVersion !== currentBuildVersion) {
            // Clear login state if build version has changed
            localStorage.removeItem('loggedIn');
            localStorage.setItem('buildVersion', currentBuildVersion);
            setLoggedIn(false);
        }
    }, []);

    const GetProgressValue = async () => {
        try {
            // First, GET the "progress" value from /api/fromAdmin
            const progressResponse = await axios.get('https://adminada.ada20coin.com/api/fromAdmin');
            const progressValue = progressResponse.data.progress;
    
            console.log("progressResponse:", progressResponse.data); // Log the entire response
    
            if (progressValue) {
                // Store the progress value in state (optional, if you need to use it elsewhere)
                setProgress(progressValue); // Assuming setProgress is a state setter
            }
    
            // Log the updated progress (after setting it)
            console.log("Updated progress:", progressValue);
    
        } catch (error) {
            console.error("Error fetching progress:", error);
        }
    };
    

    const handleLogin = async () => {
        GetProgressValue();
        try {
            const response = await axios.post('https://adminada.ada20coin.com/login', { username, password });
            if (response.data.success) {
                setLoggedIn(true);
                setErrorMessage('');
                // Store login state in localStorage
                localStorage.setItem('loggedIn', 'true');
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLogout = () => {
        // Clear login state
        setLoggedIn(false);
        // Remove 'loggedIn' key from localStorage
        localStorage.removeItem('loggedIn');
    };

    const createApi = async () => {

        try {
            const response = await axios.post('https://adminada.ada20coin.com/create-api', { value: apiValue });
            if (response.data.success) {
                setApiCreated(true);
                setApiError('');
                setApiValue(''); // Clear apiValue state
            } else {
                setApiError(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }

        GetProgressValue();
    };

    

    return (
        <div className="login-container">
            {loggedIn ? (
                <div>
                    <h1>Welcome to the <span className='color-cyan'>$ADA20</span> admin panel</h1>
                    {/* <button className="logout-button" onClick={handleLogout}>Logout</button> */}
                    <div>
                        <p>current value: <span>{progress}</span></p>
                        <input type="text" placeholder="New Value" value={apiValue} onChange={(e) => setApiValue(e.target.value)} />
                        <button className="create-api-button btn-ada20" onClick={createApi}>Update Token Value</button>
                        {apiCreated && <p className="api-success-message">Token Value updated successfully!</p>}
                        {apiError && <p className="api-error-message">{apiError}</p>}
                    </div>
                </div>
            ) : (
                <div>
                    <h1>Login</h1>
                    <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <button className="login-button" onClick={handleLogin}>Login</button>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
            )}
        </div>
    );
}

export default App;
